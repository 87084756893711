import { type LinkProps, Navigate, useNavigate } from '@remix-run/react';
import { useMemo, useState } from 'react';
import { useEffectOnce } from 'react-use';

import {
  EnumsOnboardingTaskName,
  EnumsOnboardingTaskOperation,
} from '@lp-lib/api-service-client/public';

import { useOnboardingAnalytics } from '../analytics/onboarding';
import { SlackIcon } from '../components/icons/SlackIcon';
import { ONBOARDING_PROGRAM_TYPES } from '../components/Onboarding';
import {
  OnboardingMenuBox,
  OnboardingModalLayout,
  OnboardingPageLayout,
} from '../components/Onboarding/OnboardingLayout';
import {
  useIsMyOrgSlackConnected,
  useMyOrganizationFeatureChecker,
  useMyOrgId,
} from '../components/Organization/hooks/organization';
import { SlackUtils } from '../components/Slack';
import { useLiveCallback } from '../hooks/useLiveCallback';
import { getQueryParam, useQueryParam } from '../hooks/useQueryParam';
import { apiService } from '../services/api-service';
import { getStaticAssetPath } from '../utils/assets';
import { assertExhaustive, booleanify } from '../utils/common';

function buildInviteFewMembersRedirectUrl(redirectTo: string): LinkProps['to'] {
  const search = new URLSearchParams(window.location.search);
  search.set('redirect-to', redirectTo);
  return {
    pathname: '/onboarding/invite-few-members',
    search: search.toString(),
  };
}

type MenuKey = 'live-game' | 'ond-game' | 'programs';

function useShowMenuKeys() {
  const featureChecker = useMyOrganizationFeatureChecker();

  const showOnDGame = useMemo(() => {
    const q = getQueryParam('show-ond-game');
    if (!!q) {
      return booleanify(q);
    }
    return featureChecker.hasUnlimitedOndGamePlayAccess();
  }, [featureChecker]);
  const showLiveGame = useMemo(() => {
    const q = getQueryParam('show-live-game');
    if (!!q) {
      return booleanify(q);
    }
    return featureChecker.hasUnlimitedLiveBooking();
  }, [featureChecker]);
  const showPrograms = useMemo(() => {
    const q = getQueryParam('show-programs');
    if (!!q) {
      return booleanify(q);
    }
    return ONBOARDING_PROGRAM_TYPES.some((type) =>
      featureChecker.canAccessProgramOfType(type)
    );
  }, [featureChecker]);

  return useMemo(() => {
    const keys: MenuKey[] = [];
    if (showOnDGame) {
      keys.push('ond-game');
    }
    if (showLiveGame) {
      keys.push('live-game');
    }
    if (showPrograms) {
      keys.push('programs');
    }

    return keys;
  }, [showLiveGame, showOnDGame, showPrograms]);
}

function makeNextRoute(key: MenuKey | null): LinkProps['to'] {
  if (!key) {
    return buildInviteFewMembersRedirectUrl('/onboarding/completed');
  }
  switch (key) {
    case 'live-game':
      return buildInviteFewMembersRedirectUrl('/live');
    case 'ond-game':
      return buildInviteFewMembersRedirectUrl('/onboarding/ond-games');
    case 'programs':
      return {
        pathname: '/onboarding/programs',
        search: window.location.search,
      };
  }
}

export function Component() {
  const tagId = useQueryParam('tag-id');
  const isSlackConnected = useIsMyOrgSlackConnected();
  const navigate = useNavigate();
  const analytics = useOnboardingAnalytics();

  const menuKeys = useShowMenuKeys();
  const [selected, setSelected] = useState<MenuKey | null>(() =>
    menuKeys.length > 0 ? menuKeys[0] : null
  );

  const orgId = useMyOrgId() || '';
  useEffectOnce(() => {
    apiService.onboarding.operateTask(
      EnumsOnboardingTaskName.OnboardingTaskNameEnterTaskPage,
      {
        orgId,
        operation: EnumsOnboardingTaskOperation.OnboardingTaskOperationMarkDone,
      }
    );
  });

  const handleClickContinue = useLiveCallback(async (key: MenuKey | null) => {
    analytics.trackOnboardingMenuSelected({
      key,
    });

    switch (key) {
      case 'live-game':
        navigate(makeNextRoute('live-game'));
        return;
      case 'ond-game':
        navigate(makeNextRoute('ond-game'));
        return;
      case 'programs':
        if (!isSlackConnected) {
          window.location.href = await SlackUtils.GenerateSlackInstallURL({
            scenario: 'connect',
            redirectTo: '/onboarding/programs',
          });
          return;
        }
        navigate(makeNextRoute('programs'));
        return;
      case null:
        navigate(makeNextRoute(null));
        return;
      default:
        assertExhaustive(key);
    }
  });

  if (tagId) {
    return <Navigate to={makeNextRoute('ond-game')} replace />;
  }
  if (menuKeys.length === 0) {
    return <Navigate to={makeNextRoute(null)} replace />;
  }
  if (menuKeys.length === 1) {
    return <Navigate to={makeNextRoute(menuKeys[0])} replace={true} />;
  }

  return (
    <OnboardingPageLayout progress={60}>
      <OnboardingModalLayout>
        <h2 className='text-2xl font-medium text-tertiary'>
          Let's Get Started!
        </h2>
        <p className='mt-2.5 px-8 text-base font-bold text-white text-center'>
          We’ll get you up and running quickly! What would you like to do first?
        </p>
        <div className='mt-10 w-full flex flex-col gap-2'>
          {menuKeys.includes('ond-game') && (
            <OnboardingMenuBox className='pl-5 pr-13 py-6'>
              <input
                type='radio'
                checked={selected === 'ond-game'}
                onChange={() => setSelected('ond-game')}
                className='field-radio w-4 h-4 flex-none'
              />
              <img
                src={getStaticAssetPath(
                  'images/onboarding/menu-ond-game-v2.png'
                )}
                className={`w-18 h-12.5 object-contain`}
                alt='cover'
              />
              <div className='flex-1'>
                <h3 className='text-lg font-bold text-white'>
                  Try out a team game
                </h3>
                <p className='text-3xs text-white'>
                  Games designed to build trust, but feel just like having fun.
                </p>
              </div>
            </OnboardingMenuBox>
          )}

          {menuKeys.includes('programs') && (
            <OnboardingMenuBox className='pl-5 pr-13 py-6'>
              <input
                type='radio'
                checked={selected === 'programs'}
                onChange={() => setSelected('programs')}
                className='field-radio w-4 h-4 flex-none'
              />
              <div className='w-18 h-12.5 flex justify-center items-center'>
                <SlackIcon className='w-9 h-9' />
              </div>
              <div className='flex-1'>
                <h3 className='text-lg font-bold text-white'>
                  Set Up Slack Programs
                </h3>
                <p className='text-3xs text-white'>
                  A suite of powerful Slack tools to drive ongoing engagement
                  across your team.
                </p>
              </div>
            </OnboardingMenuBox>
          )}

          {menuKeys.includes('live-game') && (
            <OnboardingMenuBox className='pl-5 pr-13 py-6'>
              <input
                type='radio'
                checked={selected === 'live-game'}
                onChange={() => setSelected('live-game')}
                className='field-radio w-4 h-4 flex-none'
              />
              <img
                src={getStaticAssetPath(
                  'images/onboarding/menu-live-game-v2.png'
                )}
                className={`w-18 h-12.5 object-contain`}
                alt='cover'
              />
              <div className='flex-1'>
                <h3 className='text-lg font-bold text-white'>
                  Schedule a live virtual event
                </h3>
                <p className='text-3xs text-white'>
                  Take your next team building event to another level. Book a
                  live hosted experience your team will love!
                </p>
              </div>
            </OnboardingMenuBox>
          )}
        </div>
        <button
          type='button'
          onClick={() => handleClickContinue(selected)}
          className='mt-8 w-100 h-15 btn-primary'
        >
          Continue
        </button>
      </OnboardingModalLayout>
    </OnboardingPageLayout>
  );
}
